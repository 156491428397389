import { useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import {
  InfoViewActions,
  useInfoViewActionsContext,
} from '@crema/context/InfoViewContextProvider';
import {
  getBrowserLocale,
  isRequestSuccessful,
  sanitizeData,
} from '@crema/helpers';
import Xaxios, { isResponse401Unauthorized } from '@crema/services/axios';
import {
  APIErrorProps,
  APIErrorResponse,
  APIErrorResProps,
} from '@crema/models/APIDataProps';
import { AxiosResponse } from 'axios';
import { AxiosError, isAxiosError } from 'axios';
import { AuthActions } from '@crema/hooks/AuthHooks';
import { useJWTAuthActions } from '@crema/services/auth/JWTAuthProvider';
import { apiUrl } from '@crema/constants/AppConst';

export const useGetDataApi = <T, F = T>(
  url: string,
  initialData?: F,
  params?: any,
  initialCall?: boolean,
  callbackFun?: (data: T) => void,
  callbackData?: (data: T) => F
) => {
  if (initialCall === undefined) {
    initialCall = true;
  }

  // console.log('API HOOKS');
  const { fetchError, fetchStart, fetchSuccess, fetchStop } =
    useInfoViewActionsContext();
  const { logout } = useJWTAuthActions();

  const [initialUrl, setInitialUrl] = useState<string>(url);
  const [allowApiCall, setAllowApiCall] = useState<boolean>(initialCall);
  const [loading, setLoading] = useState<boolean>(initialCall);
  const [apiData, setData] = useState<F>(initialData ? initialData : ([] as F));
  const [queryParams, updateQueryParams] = useState<object>(params);
  const resStateRef = useRef<boolean>(false);
  const didCancelRef = useRef<boolean>(false);
  const controller = new AbortController();

  const updateInitialUrl = (value: string) => {
    setAllowApiCall(true);
    setInitialUrl(value);
  };

  const reCallAPI = () => {
    setQueryParams(queryParams);
  };

  const setQueryParams = (queryParams: object) => {
    setLoading(true);
    updateQueryParams({ ...queryParams });
    setAllowApiCall(true);
  };

  const fetchNewWithParams = (queryParams: object) => {
    didCancelRef.current = true;
    if (resStateRef.current) {
      controller.abort();
    }

    resStateRef.current = false;
    setQueryParams(queryParams);
  };

  const setPage = (page: number) => {
    const newHash = generateHash({ page: page }, true);
    console.log('Setting page: location.hash newHash', page, newHash);
    // eslint-disable-next-line no-restricted-globals
    location.hash = newHash;

    setQueryParams({ ...queryParams, page: page + 1 });
  };

  useEffect(() => {
    didCancelRef.current = false;

    const fetchData = () => {
      resStateRef.current = true;
      let params = {};
      if (!isEmpty(queryParams)) {
        params = {
          ...trimObjectValues(queryParams),
        };
      }

      fetchStart();
      Xaxios.get(initialUrl, {
        params: sanitizeData(params),
        signal: controller.signal,
        headers: {
          'Accept-Language': getBrowserLocale(),
        },
      })
        .then((data: AxiosResponse) => {
          // console.log(
          //   initialUrl,
          //   data.data,
          //   didCancelRef.current,
          //   isRequestSuccessful(data.status)
          // );

          resStateRef.current = false;
          if (!didCancelRef.current) {
            if (isRequestSuccessful(data.status)) {
              setLoading(false);
              if (callbackData) {
                setData(callbackData(data.data));
              } else {
                setData(data.data);
              }
              if (callbackFun) callbackFun(data.data);
            } else {
              setLoading(false);
              console.log('Error', data.data);
              fetchError(data.data.error ? data.data.error : data.data.message);
              setData(initialData ? initialData : ({} as F));
              if (callbackFun) callbackFun(data.data);
            }
          }
        })
        .catch((error: APIErrorProps) => {
          if (isAxiosError(error)) {
            const axiosErrors = error as AxiosError;
            if (axiosErrors.response && axiosErrors.response.status === 401) {
              console.error('useGetDataApi error 401');
              setLoading(false);
              logout();
              return;
            }
          }

          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (callbackFun) callbackFun(error.response.data as T);

            console.error('1. fetchError will call', {
              initialUrl,
              response: error.response,
              message: error.response.data.message,
            });

            fetchError(error.response.data.message);
          } else {
            // canceled request, we can ignore
            if (error.message === 'canceled') {
              setLoading(false);
              return;
            }

            console.error('2. fetchError will call', {
              initialUrl,
              response: error.response,
              message: error.message,
            });

            fetchError(error.message!);
            if (callbackFun) callbackFun(error as T);
          }
          setLoading(false);
        })
        .finally(() => {
          fetchStop();
        });
    };
    if (allowApiCall && !resStateRef.current) fetchData();
    return () => {
      didCancelRef.current = true;
    };
  }, [initialUrl, queryParams, allowApiCall]);
  return [
    {
      loading,
      apiData,
      initialUrl,
    },
    {
      setData,
      setLoading,
      updateInitialUrl,
      setQueryParams,
      reCallAPI,
      fetchNewWithParams,
      setPage,
    },
  ] as const;
};

export const useGetDataApiX = <T, F = T>({
  url,
  initialData,
  params,
  initialCall,
  callbackFun,
  callbackData,
}: {
  url: string;
  initialData?: F;
  params?: any;
  initialCall?: boolean;
  callbackFun?: (data: T) => void;
  callbackData?: (data: T) => F;
}) => {
  if (initialCall === undefined) {
    initialCall = true;
  }

  // console.log('API HOOKS');
  const { fetchError, fetchStart, fetchSuccess, fetchStop } =
    useInfoViewActionsContext();
  const { logout } = useJWTAuthActions();

  const [initialUrl, setInitialUrl] = useState<string>(url);
  const [allowApiCall, setAllowApiCall] = useState<boolean>(initialCall);
  const [loading, setLoading] = useState<boolean>(initialCall);
  const [apiData, setData] = useState<F>(initialData ? initialData : ([] as F));
  const [queryParams, updateQueryParams] = useState<object>(params);
  const resStateRef = useRef<boolean>(false);
  const didCancelRef = useRef<boolean>(false);
  const controller = new AbortController();

  const updateInitialUrl = (value: string) => {
    setAllowApiCall(true);
    setInitialUrl(value);
  };

  const reCallAPI = () => {
    setQueryParams(queryParams);
  };

  const setQueryParams = (queryParams: object) => {
    setLoading(true);
    updateQueryParams({ ...queryParams });
    setAllowApiCall(true);
  };

  const fetchNewWithParams = (queryParams: object) => {
    didCancelRef.current = true;
    if (resStateRef.current) {
      controller.abort();
    }

    resStateRef.current = false;
    setQueryParams(queryParams);
  };

  const setPage = (page: number) => {
    const newHash = generateHash({ page: page }, true);
    console.log('Setting page: location.hash newHash', page, newHash);
    // eslint-disable-next-line no-restricted-globals
    location.hash = newHash;

    setQueryParams({ ...queryParams, page: page + 1 });
  };

  useEffect(() => {
    didCancelRef.current = false;

    const fetchData = () => {
      resStateRef.current = true;
      let params = {};
      if (!isEmpty(queryParams)) {
        params = {
          ...trimObjectValues(queryParams),
        };
      }

      fetchStart();
      Xaxios.get(initialUrl, {
        params: sanitizeData(params),
        signal: controller.signal,
        headers: {
          'Accept-Language': getBrowserLocale(),
        },
      })
        .then((data: AxiosResponse) => {
          // console.log(
          //   initialUrl,
          //   data.data,
          //   didCancelRef.current,
          //   isRequestSuccessful(data.status)
          // );

          console.log(
            'useGetDataApi',
            initialUrl,
            data.data,
            isRequestSuccessful(data.status)
          );

          resStateRef.current = false;
          if (!didCancelRef.current) {
            if (isRequestSuccessful(data.status)) {
              setLoading(false);
              if (callbackData) {
                setData(callbackData(data.data));
              } else {
                setData(data.data);
              }
              if (callbackFun) callbackFun(data.data);
            } else {
              setLoading(false);
              console.log('Error', data.data);
              fetchError(data.data.error ? data.data.error : data.data.message);
              setData(initialData ? initialData : ({} as F));
              if (callbackFun) callbackFun(data.data);
            }
          }
        })
        .catch((error: APIErrorProps) => {
          if (isAxiosError(error)) {
            const axiosErrors = error as AxiosError;
            if (axiosErrors.response && axiosErrors.response.status === 401) {
              console.error('useGetDataApi error 401');
              setLoading(false);
              logout();
              return;
            }
          }

          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (callbackFun) callbackFun(error.response.data as T);

            console.error('1. fetchError will call', {
              initialUrl,
              response: error.response,
              message: error.response.data.message,
            });

            fetchError(error.response.data.message);
          } else {
            // canceled request, we can ignore
            if (error.message === 'canceled') {
              setLoading(false);
              return;
            }

            console.error('2. fetchError will call', {
              initialUrl,
              response: error.response,
              message: error.message,
            });

            fetchError(error.message!);
            if (callbackFun) callbackFun(error as T);
          }
          setLoading(false);
        })
        .finally(() => {
          fetchStop();
        });
    };
    if (allowApiCall && !resStateRef.current) fetchData();
    return () => {
      didCancelRef.current = true;
    };
  }, [initialUrl, queryParams, allowApiCall]);
  return [
    {
      loading,
      apiData,
      initialUrl,
    },
    {
      setData,
      setLoading,
      updateInitialUrl,
      setQueryParams,
      reCallAPI,
      fetchNewWithParams,
      setPage,
    },
  ] as const;
};

export const trimObjectValues = (obj: any) => {
  if (isEmpty(obj)) {
    return obj;
  }
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'string') {
      obj[key] = obj[key].trim();
    }
  });
  return obj;
};

const handleApiResponse = <T,>(
  url: string,
  fetchSuccess: () => void,
  data: AxiosResponse<T>,
  resolve: (data: T) => void,
  reject: (data: APIErrorResProps) => void
) => {
  // console.log(url, data.data);
  fetchSuccess();
  if (isRequestSuccessful(data.status)) {
    return resolve(data.data);
  } else {
    return reject(data.data as APIErrorResProps);
  }
};

const handleAPIError = (
  url: string,
  fetchSuccess: () => void,
  error: APIErrorProps,
  reject: (data: APIErrorResProps) => void
) => {
  console.log('handleAPIError', url, error.response?.data?.message);
  fetchSuccess();

  /*if (isAxiosError(error)) {
    const axiosErrors = error as AxiosError;
    if (axiosErrors.response && axiosErrors.response.status === 401) {
      console.error('handleAPIError error 401');
      return;
    }
  }*/

  if (error.response.data.message) {
    return reject(error.response.data);
  } else {
    return reject(error as APIErrorResProps);
  }
};

export const postDataApi = <T,>(
  url: string,
  infoViewContext: InfoViewActions,
  authContext: AuthActions,
  payload: object,
  isHideLoader?: boolean,
  headers = {}
): Promise<T> => {
  const { fetchStart, fetchSuccess, fetchStop } = infoViewContext;
  // const {logout} = useJWTAuthActions();

  return new Promise((resolve, reject) => {
    if (!isHideLoader) fetchStart();
    const locale = getBrowserLocale();

    Xaxios.post(
      url,
      sanitizeData(payload),
      headers
        ? {
            headers: {
              ...headers,
              'Accept-Language': locale,
            },
          }
        : {
            headers: {
              'Accept-Language': locale,
            },
          }
    )
      .then((data: AxiosResponse) => {
        const resultAsAPIErrorResponse =
          data.data as unknown as APIErrorResponse;

        if (resultAsAPIErrorResponse.error) {
          return reject(resultAsAPIErrorResponse.error);
        } else {
          return handleApiResponse<T>(url, fetchSuccess, data, resolve, reject);
        }
      })
      .catch((error: APIErrorProps) => {
        return handleAPIError(url, fetchSuccess, error, reject);
      })
      .finally(() => {
        fetchStop();
      });
  });
};

export const basePostDataApi = <T,>(
  url: string,
  payload: object,
  headers = {}
): Promise<T> => {
  return new Promise((resolve, reject) => {
    const locale = getBrowserLocale();

    Xaxios.post(
      url,
      sanitizeData(payload),
      headers
        ? {
            headers: {
              ...headers,
              'Accept-Language': locale,
            },
          }
        : {
            headers: {
              'Accept-Language': locale,
            },
          }
    )
      .then((data: AxiosResponse) => {
        const resultAsAPIErrorResponse =
          data.data as unknown as APIErrorResponse;

        if (resultAsAPIErrorResponse.error) {
          return reject(resultAsAPIErrorResponse.error);
        } else {
          return handleApiResponse<T>(url, () => {}, data, resolve, reject);
        }
      })
      .catch((error: APIErrorProps) => {
        return handleAPIError(url, () => {}, error, reject);
      })
      .finally(() => {
        //fetchStop();
      });
  });
};

export const putDataApi = <T,>(
  url: string,
  infoViewContext: InfoViewActions,
  authContext: AuthActions,
  payload: object,
  isHideLoader = false
): Promise<T> => {
  const { fetchStart, fetchSuccess } = infoViewContext;
  return new Promise((resolve, reject) => {
    if (!isHideLoader) fetchStart();
    Xaxios.put(url, sanitizeData(payload), {
      headers: {
        headers: {
          'Accept-Language': getBrowserLocale(),
        },
      },
    })
      .then((data: AxiosResponse) => {
        return handleApiResponse<T>(url, fetchSuccess, data, resolve, reject);
      })
      .catch((error: APIErrorProps) => {
        return handleAPIError(url, fetchSuccess, error, reject);
      });
    return Promise.resolve();
  });
};

export const basePutDataApi = <T,>(
  url: string,
  payload: object
): Promise<T> => {
  return new Promise((resolve, reject) => {
    Xaxios.put(url, sanitizeData(payload), {
      headers: {
        headers: {
          'Accept-Language': getBrowserLocale(),
        },
      },
    })
      .then((data: AxiosResponse) => {
        return handleApiResponse<T>(url, () => {}, data, resolve, reject);
      })
      .catch((error: APIErrorProps) => {
        return handleAPIError(url, () => {}, error, reject);
      });
    return Promise.resolve();
  });
};

export const getDataApi = <T,>(
  url: string,
  infoViewContext: InfoViewActions,
  authContext: AuthActions,
  params = {},
  isHideLoader = false,
  headers = {}
): Promise<T> => {
  const { fetchStart, fetchSuccess } = infoViewContext;
  return new Promise((resolve, reject) => {
    if (!isHideLoader) fetchStart();
    Xaxios.get(url, {
      params: sanitizeData(params),
      headers: {
        ...headers,
        'Accept-Language': getBrowserLocale(),
      },
    })
      .then((data: AxiosResponse) => {
        return handleApiResponse<T>(url, fetchSuccess, data, resolve, reject);
      })
      .catch((error: APIErrorProps) => {
        if (isAxiosError(error) && isResponse401Unauthorized(error)) {
          authContext.logout();
        }

        return handleAPIError(url, fetchSuccess, error, reject);
      });
    return Promise.resolve();
  });
};

export const deleteDataApi = <T,>(
  url: string,
  infoViewContext: InfoViewActions,
  authContext: AuthActions,
  params = {},
  isHideLoader = false
): Promise<T> => {
  const { fetchStart, fetchSuccess, fetchStop } = infoViewContext;
  return new Promise((resolve, reject) => {
    if (!isHideLoader) fetchStart();
    Xaxios.delete(url, { params })
      .then((data: AxiosResponse) => {
        // delete expects 204 status code for a successful operation
        if (data.status === 204) {
          // fetchSuccess();
          return resolve(data.data);
        } else {
          return reject(data.data as APIErrorResProps);
        }
      })
      .catch((error: APIErrorProps) => {
        return handleAPIError(url, fetchSuccess, error, reject);
      })
      .finally(() => {
        fetchStop();
      });
    return Promise.resolve();
  });
};

export const uploadDataApi = <T,>(
  url: string,
  infoViewContext: InfoViewActions,
  authContext: AuthActions,
  payload = {},
  isHideLoader = false,
  onUploadProgress = () => {
    console.log('');
  },
  allowDownload = false
): Promise<T> => {
  const { fetchStart, fetchSuccess, fetchStop } = infoViewContext;
  return new Promise((resolve, reject) => {
    if (!isHideLoader) fetchStart();
    Xaxios.post(url, payload, {
      onUploadProgress,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: allowDownload ? 'arraybuffer' : 'stream',
    })
      .then((data: AxiosResponse) => {
        return handleApiResponse<T>(url, fetchSuccess, data, resolve, reject);
      })
      .catch((error: APIErrorProps) => {
        return handleAPIError(url, fetchSuccess, error, reject);
      })
      .finally(() => {
        fetchStop();
      });

    return Promise.resolve();
  });
};

export const uploadPutDataApi = <T,>(
  url: string,
  infoViewContext: InfoViewActions,
  payload = {},
  isHideLoader = false
) => {
  const { fetchStart, fetchSuccess, fetchStop } = infoViewContext;
  return new Promise((resolve, reject) => {
    if (!isHideLoader) fetchStart();
    Xaxios.put(url, payload, {
      headers: {
        'content-type': 'multipart/form-data',
        'Accept-Language': getBrowserLocale(),
      },
    })
      .then((data: AxiosResponse) => {
        return handleApiResponse<T>(url, fetchSuccess, data, resolve, reject);
      })
      .catch((error: APIErrorProps) => {
        return handleAPIError(url, fetchSuccess, error, reject);
      })
      .finally(() => {
        fetchStop();
      });
    return Promise.resolve();
  });
};

export const generateHash = (
  search: { [key: string]: any },
  extend = false
) => {
  // eslint-disable-next-line no-restricted-globals
  const searchParams = new URLSearchParams(
    // eslint-disable-next-line no-restricted-globals
    extend ? location.hash.substring(1) : ''
  );

  Object.keys(search).forEach((key) => {
    if (searchParams.has(key)) {
      searchParams.set(key, search[key]);
    } else {
      searchParams.append(key, search[key]);
    }
  });

  return searchParams.toString();
};

export const readHash = <IO, T extends readonly (keyof IO)[]>(defaults: IO) => {
  const searchParams = new URLSearchParams(
    // eslint-disable-next-line no-restricted-globals
    location.hash.substring(1)
  );

  let foundValues = {};
  searchParams.forEach((value, key) => {
    foundValues = { ...foundValues, [key]: value };
    // if (searchParams.has(key)) {
    //   searchParams.set(key, search[key]);
    // } else {
    //   searchParams.append(key, search[key]);
    // }
  });

  // console.log('foundValues', foundValues);

  const returnObj = { ...defaults, ...foundValues } as {
    [K in T[number]]: IO[K];
  };
  // const returnObj = { ...defaults, ...foundValues };
  return returnObj;
};

type FetcherParams = { url: string } & {
  [key: string]: any;
};

export const fetcher = async <T,>(url: FetcherParams | string) => {
  console.log('Fetcher called object', url);

  let callUrl;
  let params = {};
  if (typeof url === 'string') {
    callUrl = (url.startsWith('/') ? apiUrl.substring(0, -1) : apiUrl) + url;
  } else {
    callUrl =
      (url.url.startsWith('/') ? apiUrl.substring(0, -1) : apiUrl) + url.url;
    params = url.query;
  }

  const x = await Xaxios.get(callUrl, {
    params: params,
    headers: {
      'Accept-Language': getBrowserLocale(),
    },
  }).then((res: any) => {
    if (res.data.meta) {
      return res.data;
    } else if (res.data.data !== undefined) {
      return res.data.data;
    } else {
      return res.data;
    }
  });

  return x as T;
};

export const postFetcher = <T,>(url: FetcherParams | string) => {
  let callUrl;
  let params = {};
  if (typeof url === 'string') {
    callUrl = (url.startsWith('/') ? apiUrl.substring(0, -1) : apiUrl) + url;
  } else {
    callUrl =
      (url.url.startsWith('/') ? apiUrl.substring(0, -1) : apiUrl) + url.url;
    params = url.query;
  }

  const x = Xaxios.post(
    callUrl,
    {
      params: params,
      // headers: {
      //   'Accept-Language': readFromStorage(
      //     'locale',
      //     defaultConfig.locale.locale,
      //     'string'
      //   ),
      // },
    },
    {
      headers: {
        'Accept-Language': getBrowserLocale(),
      },
    }
  ).then((res: any) => {
    if (res.data.data !== undefined) {
      return res.data.data;
    } else {
      return res.data;
    }
  });

  return x as T;
};

export const fetcherX = <T,>(url: string, token = '') => {
  const callUrl =
    (url.startsWith('/') ? apiUrl.substring(0, -1) : apiUrl) + url;

  if (token) {
    Xaxios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  const x = Xaxios.get(callUrl, {
    headers: {
      'Accept-Language': getBrowserLocale(),
    },
  }).then((res: any) => {
    return res.data;
  });

  return x as Promise<T>;
};
