import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import defaultConfig from '@crema/constants/defaultConfig';
import {
  useThemeActionsContext,
  useThemeContext,
} from './ThemeContextProvider';
import { LanguageProps } from '@crema/models/Apps';
import { readFromStorage, writeToStorage } from '@crema/helpers';
import { getDataForLocale } from '../../../components/src/lib/AppLngSwitcher/data';
import { updateProfileLocale } from '../../../services/api-data/useProfile';

export interface LocaleContextData {
  locale: LanguageProps;
}

export interface LocaleActionsData {
  updateLocale: (locale: LanguageProps) => void;
}

export const LocaleContext = createContext<LocaleContextData>({
  locale: defaultConfig.locale,
});
export const LocaleActionsContext = createContext<LocaleActionsData>({
  updateLocale: () => {},
});

export const useLocaleContext = () => useContext(LocaleContext);

export const useLocaleActionsContext = () => useContext(LocaleActionsContext);

interface LocaleContextProviderProps {
  children: ReactNode;
}

const LocaleContextProvider: React.FC<LocaleContextProviderProps> = ({
  children,
}) => {
  const defLocale = defaultConfig.locale.locale;
  const [locale, updateLocale] = useState<LanguageProps>(
    getDataForLocale(readFromStorage('locale', defLocale, 'string'))
  );
  const { theme } = useThemeContext();
  const { updateTheme } = useThemeActionsContext();

  useEffect(() => {
    // console.log('should update locale in storage ? ', locale);

    if (
      locale.locale !==
      readFromStorage('locale', defaultConfig.locale.locale, 'string')
    ) {
      writeToStorage('locale', locale.locale);

      updateProfileLocale(locale.locale).then((res) => {
        // console.log('updateProfileLocale', res);
        // if (res === true) {
        //
        // }
      });
    } else {
      //console.log('no need to update locale in storage');
    }
  }, [locale]);

  useEffect(() => {
    updateTheme({
      ...theme,
    });
  }, [locale, theme, updateTheme]);

  return (
    <LocaleContext.Provider
      value={{
        locale,
      }}
    >
      <LocaleActionsContext.Provider
        value={{
          updateLocale,
        }}
      >
        {children}
      </LocaleActionsContext.Provider>
    </LocaleContext.Provider>
  );
};

export default LocaleContextProvider;
