import { basePutDataApi } from '@crema/hooks/APIHooks';
import { StatusResponseType } from '@src/modules/apps/context/GuideContextProvider';

// export const useProfile = <T>(userId: string) => {
//   const { fetchStart, fetchStop } = useInfoViewActionsContext();
//   const [list, setList] = useState<T[]>([]);
//   const { data, error, isLoading, isValidating, mutate } = useSWR<{ data: T }>(
//     userId ? `/api/user/profile` : null,
//     backendFetcher,
//     { ...SWRConfigNoRefresh }
//   );
//
//   // reactive for loading indicator
//   useEffect(() => {
//     console.log('isLoading useProfile', isLoading, isValidating);
//
//     if (isLoading || isValidating) {
//       fetchStart();
//     } else {
//       fetchStop();
//     }
//   }, [isLoading, isValidating]);
//
//   useEffect(() => {
//     if (!data) {
//       return;
//     }
//
//     setList(data.data as T[]);
//   }, [data]);
//
//   return {
//     data: list as T[],
//     isLoading,
//     isValidating,
//     isError: error,
//     mutate,
//   };
// };

// TODO Maybe move it to a different place ?
export const updateProfileLocale = async (locale: string) => {
  return new Promise<boolean>((resolve, reject) => {
    basePutDataApi<StatusResponseType>(`/user/locale`, {
      locale: locale,
    }).then((data) => {
      if (data.status.toString() === '201') {
        resolve(true);
      } else if (data.status.toString() === '204') {
        resolve(false);
      } else {
        reject(false);
      }
    });
  });
};
